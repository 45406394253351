$mobileG: 480px;
$tabletS: 580px;
$tabletM: 768px;
$deskS: 980px;
$deskM: 1024px;

@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../assets/fonts/PT_Sans/ptsans-regular.woff2") format("woff2");
}

@font-face {
  font-family: "PT Sans";
  font-style: bold;
  font-weight: 600;
  font-display: swap;
  src: url("../assets/fonts/PT_Sans/ptsans-bold.woff2") format("woff2");
}

@font-face {
  font-family: "Teko";
  font-display: swap;
  src: url("../assets/fonts/Teko/Teko-VariableFont_wght.ttf") format("truetype");
}

*,
*::before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: 10px;
  font-family: "PT Sans", sans-serif;
  color: white;
  cursor: default;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Teko", sans-serif;
  font-weight: 500;
  margin: 1.2rem 0;
}

a {
  display: inline-block;
}

body {
  background: linear-gradient(0deg, #080126, #23e8ff, #16006c);
  margin: 0 auto;
  font-size: 1.4rem;
  width: 100vw;
  overflow-x: hidden;
}

p {
  margin: 1.2rem 0;
  padding: 0;
  margin-block-end: 0;
  margin-block-start: 0;
}

img {
  max-width: 100%;
  height: auto;
}

.mirror{
  transform: scale(-1);
}


@mixin gradientText ($degrees:180deg, $fromColor, $toColor) {
  background-clip: text;
  background: linear-gradient($degrees, $fromColor 0%, $toColor 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
/* Common Styles */
.gradient-text-blue {
  @include gradientText(180deg, #1b7d94,  #16006c);
}

.gradient-text-green-blue {
  @include gradientText(180deg, #20dfb3, #16006c)
}

.green-text {
  color: #20dfb3;
}

.orange-text {
  color: #f99b1c;
}

.common-block {
  background-color: white;
  border-radius: 15px;
  color: #273F63;
  padding: 6rem 4rem;
  gap: 0;
  flex-basis: 48%;

  @media screen and (max-width: $tabletM) {
    padding: 6vw 6vw;
    gap: 0;
    flex-basis: 100%;
    margin-bottom: 5vh;
  }

  @media screen and (max-width: $mobileG) {
    padding: 9vw 6vw;
  }

  & a {
    color: #f99b1c;
}

}

.common-block__title {
  font-weight: 500;
  font-size: clamp(4.6rem, 4vw, 5.6rem);
  line-height: 90%;
  text-align: center;
  margin-block-start: 0;
  margin-block-end: 0;
  align-items: center;
  justify-content: center;
  align-self:flex-start;

  @media screen and (max-width: $deskS) {
    font-size: clamp(4rem, 5vw, 50px);
    margin: 1vh auto;
  }

  @media screen and (max-width: $tabletM) {
    font-size: clamp(3.5rem, 10vw, 50px);
    margin: 1vh auto;
  }
}

.title__icon {
  @media screen and (max-width: $mobileG) {
    width: 0.75em;
  }
}

.title__icon--fix {
  transform: translateY(-0.05em);
}

.featured-paragraph {
  font-size: 1.9rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.6;
  border-left: 0.5rem solid #16006c;
  padding: 1rem 2rem;
  letter-spacing: 0rem;
}

p.common-paragraph {
  margin: 0.5rem auto;
  font-weight: 450;
  font-size: 1.8rem;
  line-height: 1.6;
  width: 100%;
  

  @media screen and (max-width: $mobileG) {
    font-size: 1.6rem;
    line-height: 1.5;
  }
}

p.capitalize-first-letter::first-letter {
  text-transform: uppercase;
  font-size: 3em;
  font-weight: bold;
  line-height: 90%;
  @media screen and (max-width: $mobileG) {
    font-size: 2em;
  }
}
ul.tech-list {
  font-size: 1.6rem;
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: $mobileG) {
    font-size: 1.4rem;
    margin: 0 auto;
  }
}
ul.tech-list li {
  margin-left: 0;
  flex-basis: 50%;
  @media screen and (max-width: $mobileG) {
    flex-basis: 100%;
    margin-bottom: 0.7rem;
  }
}

.flex {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.inline-flex {
  display: inline-flex;
  gap: 1rem;
}

.f-center {
  justify-content: center;
}

.f-basis100 {
  flex-basis: 100%;
  margin: 1.5rem 0;
  flex-grow: 1;
}

.only-desk {
  @media screen and (max-width: $tabletM) {
    display: none;
  }
}

.no-mobile {
  @media screen and (max-width: $mobileG) {
    display: none;
  }
}

.only-mob {
  display: none;

  @media screen and (max-width: $mobileG) {
    display: block;
  }
}

.only-tablet-below {
  display: none;

  @media screen and (max-width: $tabletM) {
    display: block;
  }
}

@media screen and (max-width: 480px) {

  body {
    font-size: 1.5rem;
  }

  .featured-paragraph {
    font-size: 1.9rem;
    /*  */
    line-height: 123%;
    /*  */
    padding: 1rem 2rem;
    /*  */
  }
}