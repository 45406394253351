@use "base" as *;

@mixin project__btn($fontSize: 1.5rem, $color: white, $background: linear-gradient(180deg, #20dfb3 0%, #1c8d99 56.25%, #16006c 100%)) {
  color: $color;
  background: $background;

  padding: 1rem 1.8rem 1rem 1.4rem;
  margin-top: 1rem;
  border-radius: 1.5rem;
  border: 0;
  border-bottom: 1px solid #20dfb3;
  border-left: 1px solid #20dfb3;

  font-size: $fontSize;
  text-align: center;
  letter-spacing: 0.06rem;
  line-height: 1.9879rem;
  font-weight: 500;
  text-decoration: none;

  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 0.5rem;

  transition: 200ms ease-in all;
  &:hover{
    transform: scale(1.07);
  }
}

.projects {
  background: linear-gradient(180deg, #1b6d8f 0%, #16006c 100%);
  padding: 6rem 12%;
  gap: 10rem;
  justify-content: center;

  @media screen and (max-width: $deskM) {
    padding: 6rem 6%;
  }
}
.projects__title {
  flex-basis: 100%;
  flex-grow: 1;
  max-width: fit-content;
}
.projects__title__text {
  margin: 0 2rem;
  position: relative;
  }
  @mixin planet-waves(){
    content: '';
    background-image: url('../assets/img/planet-waves.svg');
    min-width: 90%;
    max-width: 90%;
    height: 0.7em;
    position:absolute;
    transform: translateY(-100%);
  }
  .projects__title__text::before{
    @include planet-waves();
    margin-left: -1.55rem;
    transform: translateX(-100%);
  }
  .projects__title__text::after{
    @include planet-waves();
    margin-left: 1.5rem;
    transform: scaleX(-1);
  }



.waving-planets{
  max-width: 25%;
  max-height: 50px;
  flex-shrink: 1;
}
.project__title {
  justify-content: start;
  font-size: 4.5rem;
  margin-bottom: clamp(12px, 1em, 20px);
}
.projects>.project:nth-child(even) {
  flex-direction: row-reverse;
  @media screen and (max-width: $tabletS) {
    flex-direction: row;
  }
}

.project {
  flex-basis: 100%;
  max-width: 800px;
  padding: 0;
  overflow: hidden;
  transition: 500ms ease-in-out all;
  &:hover{
    transform: scale(1.04);
  }

  .project__img {
    overflow: hidden;
    flex-basis: 40%;
    position: relative;
    z-index: 1;

    @media screen and (max-width: $tabletS) {
      flex-basis: 100%;
    }

    & img {
      vertical-align: bottom;
      max-width: fit-content;
      min-height: 100%;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: -1;

      @media screen and (max-width: $tabletS) {
        max-width: 100%;
        min-width: 100%;
        min-height: auto;
        position: relative;
        left: auto;
        transform: translate(0%, 0);
        z-index: -1;
      }
    }
  }

  .project__card-content {
    flex-basis: 50%;
    flex-grow: 1;
    margin: 1.5rem auto;
    padding: 2.5rem 4rem;
  }

  .tags-container {
    margin: 1rem 0 3rem;
    row-gap: 0.5rem;
  }

  .project__tag {
    background: gray;
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    padding: 0.5rem 1.3rem;
    margin: 0;
    border-radius: 30px;
    vertical-align: middle;

    &.project__tag--green {
      background: #20dfb3;
    }

    &.project__tag--orange {
      background: #f99b1c;
    }
  }

  .project__key-point {
    color: #20dfb3;
  }

  .project__title {
    justify-content: start;
    font-size: 4.5rem;
    margin-bottom: clamp(12px, 1em, 20px);
  }

  .project__sub-title {
    min-width: 100%;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.3rem;
    margin: 0 0 0.5rem;
    padding: 0;
  }

  .project__description {
    font-size: 1.3rem;
    line-height: 115%;
    margin-bottom: 2rem;
  }

  .project__ctas {
    justify-content: flex-end;
    gap: 2rem;

    @media screen and (max-width: $tabletS) {
      justify-content: center;
    }
  }

  a.project__btn {
    @include project__btn(1.3rem)
  }

  a.project__btn--secondary {
    @include project__btn(1.3rem, #20dfb3,
      linear-gradient(180deg,
        rgba(27, 125, 148, 1) 0%,
        rgba(22, 0, 108, 1) 100%));
  }

  .project__btn__icon {
    max-width: 2rem;
  }

  .project__btn__txt {
    line-height: 1em;
  }
}






//TODO: cuenta la historia... playground
// SVG ia