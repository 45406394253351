@font-face {
  font-family: PT Sans;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("ptsans-regular.1994c235.woff2") format("woff2");
}

@font-face {
  font-family: PT Sans;
  font-style: bold;
  font-weight: 600;
  font-display: swap;
  src: url("ptsans-bold.d49f5e55.woff2") format("woff2");
}

@font-face {
  font-family: Teko;
  font-display: swap;
  src: url("Teko-VariableFont_wght.1b9ca7bc.ttf") format("truetype");
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  color: #fff;
  cursor: default;
  font-family: PT Sans, sans-serif;
  font-size: 10px;
}

h1, h2, h3, h4, h5, h6 {
  margin: 1.2rem 0;
  font-family: Teko, sans-serif;
  font-weight: 500;
}

a {
  display: inline-block;
}

body {
  background: linear-gradient(0deg, #080126, #23e8ff, #16006c);
  width: 100vw;
  margin: 0 auto;
  font-size: 1.4rem;
  overflow-x: hidden;
}

p {
  margin: 1.2rem 0;
  margin-block: 0;
  padding: 0;
}

img {
  max-width: 100%;
  height: auto;
}

.mirror {
  transform: scale(-1);
}

.gradient-text-blue {
  background: linear-gradient(#1b7d94 0%, #16006c 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.gradient-text-green-blue {
  background: linear-gradient(#20dfb3 0%, #16006c 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.green-text {
  color: #20dfb3;
}

.orange-text {
  color: #f99b1c;
}

.common-block {
  color: #273f63;
  background-color: #fff;
  border-radius: 15px;
  flex-basis: 48%;
  gap: 0;
  padding: 6rem 4rem;
}

@media screen and (width <= 768px) {
  .common-block {
    flex-basis: 100%;
    gap: 0;
    margin-bottom: 5vh;
    padding: 6vw;
  }
}

@media screen and (width <= 480px) {
  .common-block {
    padding: 9vw 6vw;
  }
}

.common-block a {
  color: #f99b1c;
}

.common-block__title {
  text-align: center;
  justify-content: center;
  align-self: flex-start;
  align-items: center;
  margin-block: 0;
  font-size: clamp(4.6rem, 4vw, 5.6rem);
  font-weight: 500;
  line-height: 90%;
}

@media screen and (width <= 980px) {
  .common-block__title {
    margin: 1vh auto;
    font-size: clamp(4rem, 5vw, 50px);
  }
}

@media screen and (width <= 768px) {
  .common-block__title {
    margin: 1vh auto;
    font-size: clamp(3.5rem, 10vw, 50px);
  }
}

@media screen and (width <= 480px) {
  .title__icon {
    width: .75em;
  }
}

.title__icon--fix {
  transform: translateY(-.05em);
}

.featured-paragraph {
  letter-spacing: 0;
  border-left: .5rem solid #16006c;
  padding: 1rem 2rem;
  font-size: 1.9rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.6;
}

p.common-paragraph {
  width: 100%;
  margin: .5rem auto;
  font-size: 1.8rem;
  font-weight: 450;
  line-height: 1.6;
}

@media screen and (width <= 480px) {
  p.common-paragraph {
    font-size: 1.6rem;
    line-height: 1.5;
  }
}

p.capitalize-first-letter:first-letter {
  text-transform: uppercase;
  font-size: 3em;
  font-weight: bold;
  line-height: 90%;
}

@media screen and (width <= 480px) {
  p.capitalize-first-letter:first-letter {
    font-size: 2em;
  }
}

ul.tech-list {
  flex-wrap: wrap;
  font-size: 1.6rem;
  display: flex;
}

@media screen and (width <= 480px) {
  ul.tech-list {
    margin: 0 auto;
    font-size: 1.4rem;
  }
}

ul.tech-list li {
  flex-basis: 50%;
  margin-left: 0;
}

@media screen and (width <= 480px) {
  ul.tech-list li {
    flex-basis: 100%;
    margin-bottom: .7rem;
  }
}

.flex {
  flex-wrap: wrap;
  gap: 1rem;
  display: flex;
}

.inline-flex {
  gap: 1rem;
  display: inline-flex;
}

.f-center {
  justify-content: center;
}

.f-basis100 {
  flex-grow: 1;
  flex-basis: 100%;
  margin: 1.5rem 0;
}

@media screen and (width <= 768px) {
  .only-desk {
    display: none;
  }
}

@media screen and (width <= 480px) {
  .no-mobile {
    display: none;
  }
}

.only-mob {
  display: none;
}

@media screen and (width <= 480px) {
  .only-mob {
    display: block;
  }
}

.only-tablet-below {
  display: none;
}

@media screen and (width <= 768px) {
  .only-tablet-below {
    display: block;
  }
}

@media screen and (width <= 480px) {
  body {
    font-size: 1.5rem;
  }

  .featured-paragraph {
    padding: 1rem 2rem;
    font-size: 1.9rem;
    line-height: 123%;
  }
}

.stars, .twinkling {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  inset: 0;
  transform: translateZ(0);
}

.stars {
  z-index: -10;
  background: #080126 url("stars.44ae148c.png") top;
}

.twinkling {
  z-index: -9;
  -ms-animation: move-twink-back 200s linear infinite;
  -moz-animation: 200s linear infinite move-twink-back;
  -o-animation: 200s linear infinite move-twink-back;
  background: url("twinkling.4e214541.png") top;
  animation: 400s linear infinite move-twink-back;
}

@keyframes move-twink-back {
  from {
    background-position: 0 0;
  }

  to {
    background-position: -10000px 5000px;
  }
}

.bg--ball1 {
  z-index: 4;
  opacity: .8;
  -ms-animation: ball1 25s ease-in-out infinite;
  background: url("green-ball.0eb0072e.png") bottom / cover no-repeat;
  width: clamp(450px, 45vw, 800px);
  height: clamp(210px, 31vh, 570px);
  animation: 25s ease-in-out infinite ball1;
  position: absolute;
  top: clamp(-100px, -5vh, 0px);
}

@keyframes ball1 {
  0% {
    left: -20%;
    transform: scale(1);
  }

  50% {
    left: -10%;
    transform: scale(.8);
  }

  100% {
    left: -20%;
    transform: scale(1);
  }
}

@media screen and (width >= 980px) and (aspect-ratio <= 1) {
  .bg--ball1 {
    width: clamp(200px, 60%, 800px);
    height: 260px;
    top: -5%;
  }

  @keyframes ball1 {
    0% {
      left: -20%;
    }

    50% {
      left: -15%;
    }

    100% {
      left: -20%;
    }
  }
}

@media screen and (width <= 980px) {
  .bg--ball1 {
    width: clamp(200px, 90%, 600px);
    height: 260px;
  }

  @keyframes ball1 {
    0% {
      left: 5%;
    }

    50% {
      left: 15%;
    }

    100% {
      left: 5%;
    }
  }
}

@media screen and (width <= 768px) {
  .bg--ball1 {
    width: clamp(460px, 90%, 600px);
    height: clamp(100px, 50%, 200px);
    top: clamp(-350px, -5%, 0px);
  }

  @keyframes ball1 {
    0% {
      left: 10%;
    }

    50% {
      left: 20%;
    }

    100% {
      left: 10%;
    }
  }
}

@media screen and (width <= 580px) {
  @keyframes ball1 {
    0% {
      opacity: .8;
      left: 47%;
      transform: translateX(-50%)scale(1);
    }

    50% {
      opacity: .6;
      left: 53%;
      transform: translateX(-50%)scale(.7);
    }

    100% {
      opacity: .8;
      left: 47%;
      transform: translateX(-50%)scale(1);
    }
  }
}

.bg--ball2 {
  filter: blur(30px);
  z-index: 3;
  -ms-animation: ball2 20s ease-in-out infinite;
  background: #16006c;
  border-radius: 100%;
  width: 38vw;
  height: 33vw;
  animation: 20s ease-in-out infinite ball2;
  position: absolute;
  top: -50vh;
}

@keyframes ball2 {
  0% {
    opacity: .5;
    left: 3vw;
    transform: scale(.8);
  }

  50% {
    opacity: .8;
    left: -20vw;
    transform: scale(1.3);
  }

  100% {
    opacity: .5;
    left: 3vw;
    transform: scale(.8);
  }
}

@media screen and (width <= 980px) {
  .bg--ball2 {
    width: 340px;
    height: 140px;
    top: -67px;
  }

  @keyframes ball2 {
    0% {
      opacity: .5;
      left: 50%;
      transform: scale(.8);
    }

    50% {
      opacity: .8;
      left: 20%;
      transform: scale(1.3);
    }

    100% {
      opacity: .5;
      left: 50%;
      transform: scale(.8);
    }
  }
}

@media screen and (width <= 768px) {
  .bg--ball2 {
    width: 340px;
    top: -47px;
  }

  @keyframes ball2 {
    0% {
      opacity: .5;
      left: 40%;
      transform: scale(.8);
    }

    50% {
      opacity: .8;
      left: 0%;
      transform: scale(1.3);
    }

    100% {
      opacity: .5;
      left: 40%;
      transform: scale(.8);
    }
  }
}

.bg--ball3 {
  z-index: 50;
  opacity: .5;
  background: url("green-ball.0eb0072e.png") top / cover no-repeat;
  width: 60vw;
  height: 35vw;
  animation: 30s ease-in-out infinite ball3;
  position: absolute;
}

@keyframes ball3 {
  0% {
    bottom: -30%;
    right: -15%;
  }

  33% {
    bottom: 0%;
    right: -25%;
  }

  66% {
    bottom: -30%;
    right: -28%;
  }

  100% {
    bottom: -30%;
    right: -15%;
  }
}

@media screen and (width <= 480px) {
  .bg--ball3 {
    animation: none;
    display: none;
  }
}

.bg--ball4 {
  z-index: 3;
  border-radius: 100%;
  width: 5vw;
  height: 5vw;
  animation: 40s ease-in-out infinite ball4;
  position: absolute;
  bottom: 35%;
  left: 7%;
}

@keyframes ball4 {
  0% {
    opacity: .5;
    bottom: 35%;
    left: 7%;
  }

  33% {
    opacity: .4;
    bottom: 32%;
    left: 15%;
  }

  66% {
    opacity: .3;
    bottom: 30%;
    left: 18%;
  }

  100% {
    opacity: .5;
    bottom: 35%;
    left: 7%;
  }
}

.bg--ball5 {
  z-index: -2;
  opacity: .6;
  background: url("green-ball.0eb0072e.png") top / cover no-repeat;
  width: 180vw;
  height: 50vw;
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translate(-50%, 50%);
}

@media screen and (width <= 480px) {
  .bg--ball5 {
    z-index: 0;
    width: 180vw;
    height: 50vw;
    bottom: 0%;
  }
}

@keyframes hero-scroll-text {
  0% {
    transform: translateY(95%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes btns {
  0% {
    filter: blur(100px);
  }

  95% {
    filter: blur(100px);
  }

  100% {
    filter: blur();
  }
}

@keyframes astronaut-message {
  0% {
    filter: blur(40px);
    transform: scale(0);
  }

  100% {
    filter: blur();
    transform: scale(1);
  }
}

.fade-in {
  opacity: 0;
  transition: all 2s ease-out;
  transform: translateY(30%);
}

.delayed-fade-in {
  transition-delay: .5s;
}

.active-fade-in {
  opacity: 4;
  transform: translateY(0);
}

.blur-fade-in {
  opacity: 0;
  filter: hue-rotate("180deg");
  transition: all .5s ease-out;
}

.active-blur-fade-in {
  opacity: 1;
  filter: hue-rotate("0deg");
}

@media screen and (width <= 768px) {
  @keyframes img-fade-in {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

@keyframes words-cloud__animation {
  0% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(359deg);
  }
}

@keyframes word__animation {
  0% {
    transform: rotateZ(359deg);
  }

  100% {
    transform: rotateZ(0);
  }
}

@keyframes words-opacity {
  0% {
    opacity: .2;
  }

  33% {
    opacity: 1;
  }

  66% {
    opacity: 1;
  }

  100% {
    opacity: .2;
  }
}

.cloud-animation {
  z-index: 1;
  opacity: .2;
  justify-content: center;
  transition: all .3s ease-in;
  animation: 35s linear infinite words-cloud__animation, 10s linear infinite words-opacity;
  position: absolute;
}

.cloud-animation--design {
  width: 35%;
  height: 90%;
  top: 6.5%;
  left: -2.5%;
}

@media screen and (width <= 980px) {
  .cloud-animation--design {
    width: 37%;
  }
}

@media screen and (width <= 768px) {
  .cloud-animation--design {
    width: 97%;
    height: 45%;
    top: auto;
    bottom: -6%;
    left: 6%;
  }
}

@media screen and (width <= 480px) {
  .cloud-animation--design {
    width: 100%;
    height: 38%;
    bottom: -2%;
    left: 6%;
  }
}

.cloud-animation--dev {
  width: 43%;
  height: 97%;
  animation-delay: 0s, 4s;
  top: -6%;
  right: -5%;
}

@media screen and (width <= 768px) {
  .cloud-animation--dev {
    width: 96%;
    height: 45%;
    animation-delay: 0s, 4s;
    top: -4%;
    right: -9%;
  }
}

.cloud-animation--web {
  width: 40%;
  height: 86%;
  animation-delay: 0s, 8s;
  top: 0%;
  left: 31%;
}

@media screen and (width <= 768px) {
  .cloud-animation--web {
    width: 75%;
    height: 35%;
    top: 33%;
    left: -16%;
  }
}

.cloud-animation__word {
  place-content: center;
  align-items: center;
  width: 16.5%;
  font-size: 1.2rem;
  font-weight: 500;
  animation: 35s linear infinite word__animation;
  display: flex;
}

@media screen and (width <= 768px) {
  .cloud-animation__word {
    max-width: 20%;
    font-size: 1.4rem;
  }
}

@media screen and (width <= 480px) {
  .cloud-animation__word {
    font-size: 1.2rem;
  }
}

.cloud-animation__word--alter {
  text-align: center;
  flex-basis: 30%;
  place-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  animation: 35s linear infinite word__animation;
  display: flex;
}

.projects {
  background: linear-gradient(#1b6d8f 0%, #16006c 100%);
  justify-content: center;
  gap: 10rem;
  padding: 6rem 12%;
}

@media screen and (width <= 1024px) {
  .projects {
    padding: 6rem 6%;
  }
}

.projects__title {
  flex-grow: 1;
  flex-basis: 100%;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.projects__title__text {
  margin: 0 2rem;
  position: relative;
}

.projects__title__text:before {
  content: "";
  background-image: url("planet-waves.51fd7802.svg");
  min-width: 90%;
  max-width: 90%;
  height: .7em;
  margin-left: -1.55rem;
  position: absolute;
  transform: translateX(-100%);
}

.projects__title__text:after {
  content: "";
  background-image: url("planet-waves.51fd7802.svg");
  min-width: 90%;
  max-width: 90%;
  height: .7em;
  margin-left: 1.5rem;
  position: absolute;
  transform: scaleX(-1);
}

.waving-planets {
  flex-shrink: 1;
  max-width: 25%;
  max-height: 50px;
}

.project__title {
  justify-content: start;
  margin-bottom: clamp(12px, 1em, 20px);
  font-size: 4.5rem;
}

.projects > .project:nth-child(2n) {
  flex-direction: row-reverse;
}

@media screen and (width <= 580px) {
  .projects > .project:nth-child(2n) {
    flex-direction: row;
  }
}

.project {
  flex-basis: 100%;
  max-width: 800px;
  padding: 0;
  transition: all .5s ease-in-out;
  overflow: hidden;
}

.project:hover {
  transform: scale(1.04);
}

.project .project__img {
  z-index: 1;
  flex-basis: 40%;
  position: relative;
  overflow: hidden;
}

@media screen and (width <= 580px) {
  .project .project__img {
    flex-basis: 100%;
  }
}

.project .project__img img {
  vertical-align: bottom;
  z-index: -1;
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-height: 100%;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

@media screen and (width <= 580px) {
  .project .project__img img {
    z-index: -1;
    min-width: 100%;
    max-width: 100%;
    min-height: auto;
    position: relative;
    left: auto;
    transform: translate(0%);
  }
}

.project .project__card-content {
  flex-grow: 1;
  flex-basis: 50%;
  margin: 1.5rem auto;
  padding: 2.5rem 4rem;
}

.project .tags-container {
  row-gap: .5rem;
  margin: 1rem 0 3rem;
}

.project .project__tag {
  color: #fff;
  text-align: center;
  vertical-align: middle;
  background: gray;
  border-radius: 30px;
  margin: 0;
  padding: .5rem 1.3rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.project .project__tag.project__tag--green {
  background: #20dfb3;
}

.project .project__tag.project__tag--orange {
  background: #f99b1c;
}

.project .project__key-point {
  color: #20dfb3;
}

.project .project__title {
  justify-content: start;
  margin-bottom: clamp(12px, 1em, 20px);
  font-size: 4.5rem;
}

.project .project__sub-title {
  text-transform: uppercase;
  min-width: 100%;
  margin: 0 0 .5rem;
  padding: 0;
  font-size: 1.3rem;
  font-weight: 700;
}

.project .project__description {
  margin-bottom: 2rem;
  font-size: 1.3rem;
  line-height: 115%;
}

.project .project__ctas {
  justify-content: flex-end;
  gap: 2rem;
}

@media screen and (width <= 580px) {
  .project .project__ctas {
    justify-content: center;
  }
}

.project a.project__btn {
  color: #fff;
  text-align: center;
  letter-spacing: .06rem;
  background: linear-gradient(#20dfb3 0%, #1c8d99 56.25%, #16006c 100%);
  border-top: 0;
  border-bottom: 1px solid #20dfb3;
  border-left: 1px solid #20dfb3;
  border-right: 0;
  border-radius: 1.5rem;
  place-content: center;
  align-items: center;
  gap: .5rem;
  margin-top: 1rem;
  padding: 1rem 1.8rem 1rem 1.4rem;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.9879rem;
  text-decoration: none;
  transition: all .2s ease-in;
}

.project a.project__btn:hover {
  transform: scale(1.07);
}

.project a.project__btn--secondary {
  color: #20dfb3;
  text-align: center;
  letter-spacing: .06rem;
  background: linear-gradient(#1b7d94 0%, #16006c 100%);
  border-top: 0;
  border-bottom: 1px solid #20dfb3;
  border-left: 1px solid #20dfb3;
  border-right: 0;
  border-radius: 1.5rem;
  place-content: center;
  align-items: center;
  gap: .5rem;
  margin-top: 1rem;
  padding: 1rem 1.8rem 1rem 1.4rem;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.9879rem;
  text-decoration: none;
  transition: all .2s ease-in;
}

.project a.project__btn--secondary:hover {
  transform: scale(1.07);
}

.project .project__btn__icon {
  max-width: 2rem;
}

.project .project__btn__txt {
  line-height: 1em;
}

header {
  z-index: 100;
  align-items: center;
  width: 100%;
  height: 15vh;
  padding: 1rem 5.7rem;
  position: absolute;
  top: 0;
}

@media screen and (width <= 980px) {
  header {
    justify-content: center;
    height: -moz-fit-content;
    height: fit-content;
    padding: 3vh 5.7rem;
  }
}

.hero {
  color: #fff;
  align-content: center;
  align-items: center;
  max-width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

@media screen and (width <= 768px) {
  .hero {
    align-content: start;
    max-width: 100%;
    height: auto;
    min-height: 130vh;
  }
}

@media screen and (width >= 768px) and (aspect-ratio <= 1) {
  .hero {
    height: 60vh;
  }
}

.hero__img-layer {
  width: 100vw;
  height: 100vh;
  position: absolute;
}

@media screen and (width <= 768px) {
  .hero__img-layer {
    height: 60vh;
    bottom: 0;
  }
}

@media screen and (width >= 768px) and (aspect-ratio <= 1) {
  .hero__img-layer {
    height: 60vh;
  }
}

.main-img {
  image-rendering: -webkit-optimize-contrast;
  position: absolute;
  bottom: -4px;
  right: 0;
}

@media screen and (width <= 980px) {
  .main-img {
    max-width: 60%;
  }
}

@media screen and (width <= 768px) {
  .main-img {
    animation: 14s linear img-fade-in;
  }
}

@media screen and (width <= 580px) {
  .main-img {
    max-width: 75%;
  }
}

@media screen and (width <= 480px) {
  .main-img {
    max-width: 100%;
  }
}

@media screen and (height <= 480px) and (aspect-ratio >= 1) {
  .main-img {
    max-width: 40%;
  }
}

.hero__text-layer {
  width: 100vw;
  height: 100vh;
  position: absolute;
}

@media screen and (width <= 768px) {
  .hero__text-layer {
    height: 100vh;
    position: relative;
  }
}

.hero__text {
  z-index: 100;
  max-width: 400px;
  height: -moz-fit-content;
  height: fit-content;
  padding-top: 5vh;
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
}

.hero__text .hero__text--mask {
  padding-bottom: 30px;
  overflow-y: hidden;
}

@media screen and (height <= 480px) and (aspect-ratio >= 1) {
  .hero__text .hero__text--mask {
    padding-bottom: 10px;
  }
}

@media screen and (width <= 980px) {
  .hero__text {
    min-width: 300px;
    max-width: 50%;
    height: -moz-fit-content;
    height: fit-content;
    padding: 0 5%;
    position: relative;
    top: 50%;
  }
}

@media screen and (width <= 768px) {
  .hero__text {
    min-width: 300px;
    max-width: 360px;
    left: 50%;
  }
}

@media screen and (height <= 480px) and (aspect-ratio >= 1) {
  .hero__text {
    max-width: 50%;
    height: 50vh;
  }

  .hero__greet {
    max-width: 200px;
  }
}

.hero__text--animation {
  -webkit-animation: 8s linear forwards hero-scroll-text;
  height: -moz-fit-content;
  height: fit-content;
  animation: 12s linear forwards hero-scroll-text;
}

.hero__intro, .hero__paragraph {
  text-align: center;
  margin: .5rem 0;
  font-family: PT Sans;
  font-size: 2.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

@media screen and (width <= 980px) {
  .hero__intro, .hero__paragraph {
    margin: .5rem 0;
    font-size: clamp(1.6rem, 4vw, 1.8rem);
    line-height: 130%;
  }
}

@media screen and (height <= 480px) and (aspect-ratio >= 1) {
  .hero__intro, .hero__paragraph {
    font-size: clamp(1.4rem, 3vh, 1.6rem);
  }
}

.hero__data-links {
  z-index: 99;
  background: #16006c4d;
  flex-shrink: 0;
  place-content: center space-evenly;
  align-items: center;
  gap: 2rem;
  width: 100%;
  min-height: 8vh;
  padding: 1.8rem 4rem;
  position: absolute;
  bottom: 0;
}

@media screen and (width <= 768px) {
  .hero__data-links {
    display: none;
  }
}

@media screen and (height <= 480px) and (aspect-ratio >= 1) {
  .hero__data-links {
    display: none;
  }
}

.hero__btns {
  gap: 3rem !important;
}

@media screen and (width <= 768px) {
  .hero__btns {
    gap: 1.5rem !important;
  }
}

a.hero-btn {
  color: #fff;
  text-align: center;
  letter-spacing: .0745rem;
  background: linear-gradient(#20dfb3 0%, #1c8d99d1 56.25%, #16006c80 100%);
  border-bottom: 1px solid #20dfb3;
  border-left: 1px solid #20dfb3;
  border-radius: 1.5rem;
  align-content: center;
  align-items: center;
  padding: 1.9rem 2.3rem 1.9rem 1.9rem;
  font-size: 1.8rem;
  font-weight: 600;
  text-decoration: none;
  transition: all .2s ease-in;
  animation: 12s btns;
  transform: scale3d(1, 1, 1);
}

@media screen and (width <= 980px) {
  a.hero-btn {
    padding: 1.3rem 1.7rem 1.3rem 1.3rem;
    font-size: 1.3rem;
    font-weight: 600;
  }
}

a.hero-btn--secondary {
  color: #20dfb3;
  background: linear-gradient(#1b7d9480 0%, #16006c80 100%);
}

@media screen and (width <= 580px) {
  a.hero-btn--secondary {
    animation: 10.2s btns;
  }
}

.btn__icon {
  align-items: center;
  max-width: 1em;
  max-height: 1.1em;
  display: inline-flex;
}

a.hero-btn:hover, a.cv-btn--small:hover, button.send-btn--small:hover {
  cursor: pointer;
  transform: scale3d(1.1, 1.1, 2.1);
}

a.data-links__a {
  color: #fff;
  align-content: center;
  text-decoration: none;
  transition: all .15s ease-in-out;
}

@media screen and (width <= 980px) {
  a.data-links__a {
    min-width: 30%;
  }
}

@media screen and (width <= 480px) {
  a.data-links__a {
    justify-content: center;
  }
}

a.data-links__a:hover {
  color: #20dfb3;
  transform: scale(1.12);
}

a.data-links__a--alter {
  text-transform: uppercase;
  align-items: center;
  font-family: Teko, sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 51.9%;
}

.about {
  background: linear-gradient(#1ebba7 0%, #1b6d8f 100%);
  justify-content: center;
  gap: 2.5vw;
  max-width: 100%;
  min-height: 100vh;
  padding: 10rem 12% 6rem;
}

@media screen and (width <= 1024px) {
  .about {
    gap: 4%;
    padding: 10rem 4% 6rem;
    overflow-x: hidden;
  }
}

@media screen and (width <= 768px) {
  .about {
    gap: 5vw;
    padding: 10rem 6% 6rem;
  }
}

.about__img {
  align-items: stretch;
  margin: 5rem auto;
  position: relative;
}

@media screen and (width <= 1024px) {
  .about__img {
    max-width: 88%;
  }
}

.about__icon {
  align-content: start;
  max-width: .75em;
}

.about__img img {
  image-rendering: -webkit-optimize-contrast;
  z-index: 2;
  margin: 3rem auto;
  position: relative;
}

a.cv-btn--small {
  color: #fff;
  text-align: center;
  letter-spacing: .06rem;
  background: linear-gradient(#20dfb3 0%, #1c8d99 56.25%, #16006c 100%);
  border-bottom: 1px solid #20dfb3;
  border-left: 1px solid #20dfb3;
  border-radius: 1.5rem;
  align-content: center;
  align-items: center;
  margin-top: 1rem;
  padding: 1rem 1.8rem 1rem 1.4rem;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.9879rem;
  text-decoration: none;
  transition: all .2s ease-in;
}

.skills-container {
  gap: 1.4rem;
  padding: 2.4rem 1.4rem 1.4rem;
}

@media screen and (width <= 480px) {
  .skills-container {
    justify-content: space-evenly;
    gap: 2.4rem;
    padding: 1.8rem 1rem 1.4rem;
  }
}

.skill-block {
  text-align: center;
  flex-basis: 16.66%;
  max-width: 160px;
}

@media screen and (width <= 480px) {
  .skill-block {
    flex-basis: 33.3%;
  }
}

.skill-block:hover * {
  transition: all .2s ease-in-out;
}

.skill-block:hover img {
  filter: drop-shadow(2px 2px 2px #16006c31);
  transform: scale(1.2);
}

.contact {
  background: linear-gradient(#1b6d8f 0%, #16006c 100%);
  padding: 6rem 12%;
}

@media screen and (width <= 1024px) {
  .contact {
    padding: 6rem 6%;
  }
}

.contact__icon {
  align-items: start;
  margin-top: .05em;
}

.contact label {
  text-align: left;
  max-width: 80%;
}

.contact__label-text {
  border: 1rem;
  margin: 0 0 .5rem;
  padding: 0 1rem;
  font-size: 1.2rem;
}

.contact input, .contact textarea {
  border: none;
  border-bottom: 2px solid #1b6d8f;
  width: 100%;
  padding: 1rem 1rem .5rem;
  font-family: PT Sans, sans-serif;
}

.contact textarea {
  resize: none;
  border: 2px solid #1b6d8f;
  border-radius: 1rem;
}

.contact input:focus, .contact input:focus-visible, .contact textarea:focus, .contact textarea:focus-visible {
  background-color: #20dfb317;
  border-bottom: 3px solid #20dfb3;
  outline: none;
}

.contact label.touched input:invalid {
  border-bottom: 2px solid red !important;
}

.contact label.touched input:valid {
  border-bottom: 3px solid green !important;
}

button.send-btn--small {
  color: #fff;
  text-align: center;
  letter-spacing: .06rem;
  background: linear-gradient(#20dfb3 0%, #1c8d99 56.25%, #16006c 100%);
  border-top: 0;
  border-bottom: 1px solid #20dfb3;
  border-left: 1px solid #20dfb3;
  border-right: 0;
  border-radius: 1.5rem;
  align-content: center;
  align-items: center;
  margin-top: 1rem;
  padding: 1rem 1.8rem 1rem 1.4rem;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.9879rem;
  text-decoration: none;
  transition: all .2s ease-in;
}

.contact__data-links {
  z-index: 10;
  flex-shrink: 0;
  place-content: center space-evenly;
  align-items: center;
  gap: 2rem;
  width: 100%;
  margin-left: 2vw;
  padding: 2rem;
  position: relative;
}

@media screen and (width <= 768px) {
  .contact__data-links {
    z-index: 10;
    text-align: center;
    flex-shrink: 0;
    align-content: center;
    align-items: center;
    width: 100%;
  }
}

.contact__data-links--w-full {
  transform-origin: 0%;
  flex-basis: 100%;
}

.contact__data-links:before {
  content: "";
  z-index: -1;
  opacity: .1;
  background: url("assets/img/icons/alber-web-icon.svg") center / cover no-repeat;
  width: 400px;
  height: 400px;
  position: absolute;
  top: 10%;
  right: 0;
}

a.contact__text-link {
  color: #20dfb3;
}

.about-alber-web a {
  color: #1c8d99;
}

a.contact__text-link:hover, .about-alber-web a:hover {
  color: #f99b1c;
}

.about-alber-web__title {
  text-align: center;
  color: #20dfb3;
  font-family: Teko, sans-serif;
  font-size: 3.1rem;
  font-weight: 500;
  line-height: 100%;
}

@media screen and (width <= 768px) {
  .contact__icon {
    align-items: start;
    max-width: 3.3rem;
    margin-top: .05em;
  }

  .contact label {
    max-width: 100%;
  }

  .contact__data-links > * {
    transform-origin: 0%;
    flex-basis: 100%;
  }

  .contact__data-links:before {
    content: "";
    z-index: -1;
    opacity: .1;
    background: url("assets/img/icons/alber-web-icon.svg") center / cover no-repeat;
    width: 400px;
    height: 400px;
    position: absolute;
    top: 10%;
    right: 0;
  }

  .about-alber-web__title {
    text-align: center;
    color: #20dfb3;
    font-family: Teko, sans-serif;
    font-size: 3.1rem;
    font-weight: 500;
    line-height: 100%;
  }
}

/*# sourceMappingURL=index.54e5a439.css.map */
