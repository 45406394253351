@use "base" as *;
@use "bg-and-animations" as *;
@use "projects" as *;

header {
  padding: 1rem 5.7rem;
  height: 15vh;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;
  align-items: center;

  @media screen and (max-width: $deskS) {
    padding: 3vh 5.7rem;
    height: fit-content;
    justify-content: center;
  }
}

/* HERO */
.hero {
  color: white;
  height: 100vh;
  max-width: 100%;

  align-items: center;
  align-content: center;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: $tabletM) {
    height: auto;
    min-height: 130vh;
    max-width: 100%;
    align-content: start;
  }

  @media screen and (min-width: $tabletM) and (max-aspect-ratio: 1/1) {
    height: 60vh;
  }
}

.hero__img-layer {
  position: absolute;
  width: 100vw;
  height: 100vh;

  @media screen and (max-width: $tabletM) {
    height: 60vh;
    bottom: 0;
  }

  @media screen and (min-width: $tabletM) and (max-aspect-ratio: 1/1) {
    height: 60vh;
  }
}

.main-img {
  position: absolute;
  right: 0;
  bottom: -4px;
  image-rendering: -webkit-optimize-contrast;

  @media screen and (max-width: $deskS) {
    max-width: 60%;
  }

  @media screen and (max-width: $tabletM) {
    animation: img-fade-in 14s linear;
  }

  @media screen and (max-width: $tabletS) {
    max-width: 75%;
  }

  @media screen and (max-width: $mobileG) {
    max-width: 100%;
  }

  @media screen and (max-height: 480px) and (min-aspect-ratio: 1/1) {
    max-width: 40%;
  }
}


.hero__text-layer {
  position: absolute;
  width: 100vw;
  height: 100vh;

  @media screen and (max-width: $tabletM) {
    position: relative;
    height: 100vh;
  }
}

.hero__text {
  max-width: 400px;
  height: fit-content;
  padding-top: 5vh;
  transform: translate(-50%, -50%);
  position: absolute;
  left: 30%;
  top: 50%;
  z-index: 100;

  //added
  .hero__text--mask{
    overflow-y: hidden;
  //   -webkit-mask-image: linear-gradient(0deg,
  //     rgba(0, 0, 0, 0) 0%,
  //     rgba(0, 0, 0, 1) 30px);
  // mask-image: linear-gradient(0deg,
  //     rgba(0, 0, 0, 0) 0%,
  //     rgba(0, 0, 0, 1) 30px);
  // background: linear-gradient(180deg, #08012600 90%, #080126 100%);
  padding-bottom: 30px;

  @media screen and (max-height: 480px) and (min-aspect-ratio: 1/1) {
    padding-bottom: 10px;

  }
  }

  @media screen and (max-width: $deskS) {
    min-width: 300px;
    max-width: 50%;
    height: fit-content;
    padding: 0 5%;
    position: relative;
    top: 50%;
  }

  @media screen and (max-width: $tabletM) {
    min-width: 300px;
    max-width: 360px;
    left: 50%;
  }

  @media screen and (max-height: 480px) and (min-aspect-ratio: 1/1) {
    height: 50vh;
    max-width: 50%;

  }
}

.hero__greet {
  @media screen and (max-height: 480px) and (min-aspect-ratio: 1/1) {
    max-width: 200px;
  }
}

// @media not all and (min-resolution:.001dpcm) {
//   @supports (-webkit-appearance:none) { @content; }
// }
.hero__text--animation {
  height: fit-content;
  -webkit-animation: hero-scroll-text 8s linear forwards;
  animation: hero-scroll-text 12s linear forwards;
  // transition: 500ms ease-in-out;
  // max-height: 400px;
  // overflow: hidden;

}

.hero__intro,
.hero__paragraph {
  text-align: center;
  font-family: "PT Sans";
  font-size: 2.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin: 0.5rem 0;

  @media screen and (max-width: $deskS) {
    font-size: clamp(1.6rem, 4vw, 1.8rem);
    line-height: 130%;
    margin: 0.5rem 0;
  }

  @media screen and (max-height: 480px) and (min-aspect-ratio: 1/1) {
    font-size: clamp(1.4rem, 3vh, 1.6rem);
  }
}

.hero__data-links {
  position: absolute;
  z-index: 99;
  bottom: 0;
  width: 100%;

  background: rgba(22, 0, 108, 0.3);

  min-height: 8vh;
  padding: 1.8rem 4rem;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  flex-shrink: 0;
  gap: 2rem;

  @media screen and (max-width: $tabletM) {
    display: none;
  }

  @media screen and (max-height: 480px) and (min-aspect-ratio: 1/1) {
    display: none;
  }
}

.hero__btns {
  gap: 3rem !important;

  @media screen and (max-width: $tabletM) {
    gap: 1.5rem !important;
  }
}

a.hero-btn {
  color: white;
  text-decoration: none;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: 0.0745rem;

  border-radius: 1.5rem;
  border-bottom: 1px solid #20dfb3;
  border-left: 1px solid #20dfb3;
  background: linear-gradient(180deg,
      #20dfb3 0%,
      rgba(28, 141, 153, 0.82) 56.25%,
      rgba(22, 0, 108, 0.5) 100%);
  padding: 1.9rem 2.3rem 1.9rem 1.9rem;
  align-content: center;

  animation: btns 12s ease;
  transform: scale3d(1, 1, 1);
  transition: 200ms ease-in;

  align-items: center;

  @media screen and (max-width: $deskS) {
    font-size: 1.3rem;
    font-weight: 600;

    padding: 1.3rem 1.7rem 1.3rem 1.3rem;
  }

  &--secondary {
    background: linear-gradient(180deg,
        rgba(27, 125, 148, 0.5) 0%,
        rgba(22, 0, 108, 0.5) 100%);
    color: #20dfb3;

    @media screen and (max-width: $tabletS) {
      animation: btns 10.2s ease;
    }
  }
}

.btn__icon {
  max-width: 1em;
  max-height: 1.1em;
  display: inline-flex;
  align-items: center;
}

a.hero-btn:hover,
a.cv-btn--small:hover,
button.send-btn--small:hover {
  transform: scale3d(1.1, 1.1, 2.1);
  cursor: pointer;
}

a.data-links__a {
  align-content: center;
  text-decoration: none;
  color: white;
  transition: 150ms ease-in-out;

  @media screen and (max-width: $deskS) {
    min-width: 30%;
  }

  @media screen and (max-width: $mobileG) {
    justify-content: center;
  }
}

a.data-links__a:hover {
  color: #20dfb3;
  transform: scale(1.12);
}

a.data-links__a--alter {
  font-family: "Teko", sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 51.9%;
  text-transform: uppercase;
  align-items: center;
}



/* ABOUT */
.about {
  max-width: 100%;
  min-height: 100vh;
  padding: 10rem 12% 6rem;
  gap: 2.5vw;
  justify-content: center;
  background: linear-gradient(180deg, #1EBBA7 0%, #1b6d8f 100%);

  @media screen and (max-width: $deskM) {
    padding: 10rem 4% 6rem;
    gap: 4%;
    overflow-x: hidden;
  }

  @media screen and (max-width: $tabletM) {
    padding: 10rem 6% 6rem;
    gap: 5vw;
  }
}

.about__img {
  position: relative;
  align-items: stretch;
  margin: 5rem auto;

  @media screen and (max-width: $deskM) {
    max-width: 88%;
  }
}

.about__icon {
  align-content: start;
  max-width: 0.75em;
}

.about__img img {
  position: relative;
  margin: 3rem auto;
  image-rendering: -webkit-optimize-contrast;
  z-index: 2;
}

a.cv-btn--small {
  color: white;
  text-decoration: none;
  padding: 1rem 1.8rem 1rem 1.4rem;
  margin-top: 1rem;

  border-radius: 1.5rem;
  border-bottom: 1px solid #20dfb3;
  border-left: 1px solid #20dfb3;
  background: linear-gradient(180deg, #20dfb3 0%, #1c8d99 56.25%, #16006c 100%);

  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.9879rem;
  letter-spacing: 0.06rem;

  align-items: center;
  align-content: center;

  transition: 200ms ease-in;
}

.skills-container {
  padding: 2.4rem 1.4rem 1.4rem;
  gap: 1.4rem;

  @media screen and (max-width: $mobileG) {
    padding: 1.8rem 1rem 1.4rem;
    gap: 2.4rem;
    justify-content: space-evenly;
  }
}

.skill-block {
  text-align: center;
  max-width: 160px;
  flex-basis: 16.66%;

  @media screen and (max-width: $mobileG) {
    flex-basis: 33.3%;
  }
}

.skill-block:hover * {
  transition: all 200ms ease-in-out;
}

.skill-block:hover img {
  transform: scale(1.2);
  filter: drop-shadow(2px 2px 2px #16006c31);
}


/* CONTACT */
.contact {
  background: linear-gradient(180deg, #1b6d8f 0%, #16006c 100%);
  padding: 6rem 12%;

  @media screen and (max-width: $deskM) {
    padding: 6rem 6%;
  }
}

.contact__icon {
  align-items: start;
  margin-top: 0.05em;
}

.contact label {
  text-align: left;
  max-width: 80%;
}

.contact__label-text {
  border: 1rem;
  font-size: 1.2rem;
  padding: 0 1rem;
  margin: 0 0 0.5rem;
}

.contact input,
.contact textarea {
  padding: 1rem 1rem 0.5rem;
  border: none;
  border-bottom: 2px solid #1b6d8f;
  width: 100%;
  font-family: "PT Sans", sans-serif;
}

.contact textarea {
  border: 2px solid #1b6d8f;
  resize: none;
  border-radius: 1rem;
}

.contact input:focus,
.contact input:focus-visible,
.contact textarea:focus,
.contact textarea:focus-visible {
  background-color: #20dfb317;
  border-bottom: 3px solid #20dfb3;
  outline: none;
}

.contact label.touched input:invalid {
  border-bottom: 2px solid red !important;
}

.contact label.touched input:valid {
  border-bottom: 3px solid green !important;
}

button.send-btn--small {
  color: white;
  text-decoration: none;
  padding: 1rem 1.8rem 1rem 1.4rem;
  margin-top: 1rem;
  border-radius: 1.5rem;
  border: 0;
  border-bottom: 1px solid #20dfb3;
  border-left: 1px solid #20dfb3;

  background: linear-gradient(180deg, #20dfb3 0%, #1c8d99 56.25%, #16006c 100%);
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.9879rem;
  letter-spacing: 0.06rem;
  align-items: center;
  align-content: center;
  transition: 200ms ease-in;
}

.contact__data-links {
  position: relative;
  width: 100%;
  gap: 2rem;
  padding: 2rem;
  margin-left: 2vw;

  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  flex-shrink: 0;
  z-index: 10;

  @media screen and (max-width: $tabletM) {
    width: 100%;
    // justify-content: space-evenly;
    align-items: center;
    align-content: center;
    flex-shrink: 0;
    z-index: 10;
    text-align: center;
  }
}

.contact__data-links--w-full {
  flex-basis: 100%;
  transform-origin: 0%;
}

.contact__data-links:before {
  content: "";
  background: url(../assets/img/icons/alber-web-icon.svg) no-repeat center;
  background-size: cover;
  position: absolute;
  top: 10%;
  right: 0;
  width: 400px;
  height: 400px;
  z-index: -1;
  opacity: 0.1;
}

a.contact__text-link {
  color: #20dfb3;
}

.about-alber-web a {
  color: #1c8d99;
}

a.contact__text-link:hover,
.about-alber-web a:hover {
  color: #f99b1c;
}

.about-alber-web__title {
  font-weight: 500;
  font-size: 3.1rem;
  line-height: 100%;
  text-align: center;
  font-family: "Teko", sans-serif;
  color: #20dfb3;
}

@media screen and (max-width: $tabletM) {

  .contact__icon {
    align-items: start;
    margin-top: 0.05em;
  }

  .contact__icon {
    max-width: 3.3rem;
  }

  .contact label {
    max-width: 100%;
  }


  .contact__data-links>* {
    flex-basis: 100%;
    transform-origin: 0%;
  }

  .contact__data-links:before {
    content: "";
    background: url(../assets/img/icons/alber-web-icon.svg) no-repeat center;
    background-size: cover;
    position: absolute;
    top: 10%;
    right: 0;
    width: 400px;
    height: 400px;
    z-index: -1;
    opacity: 0.1;
  }

  .about-alber-web__title {
    font-weight: 500;
    font-size: 3.1rem;
    line-height: 100%;
    text-align: center;
    font-family: "Teko", sans-serif;
    color: #20dfb3;
  }
}