@use "base" as *;
/*  */
.stars,
.twinkling {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
  transform: translateZ(0px);
}

.stars {
  background: #080126 url(../assets/img/stars.png) repeat top center;
  z-index: -10;
}

/* https://codepen.io/WebSonick/pen/nBPZZO */

/* KEYFRAMES AND ANIMATION RELATED CLASSES*/
.twinkling {
  background: transparent url(../assets/img/twinkling.png) repeat top center;
  z-index: -9;
  -moz-animation: move-twink-back 200s linear infinite;
  -ms-animation: move-twink-back 200s linear infinite;
  -o-animation: move-twink-back 200s linear infinite;
  -webkit-animation: move-twink-back 400s linear infinite;
  animation: move-twink-back 400s linear infinite;
}
/* Twink */
@keyframes move-twink-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 5000px;
  }
}
@-webkit-keyframes move-twink-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 5000px;
  }
}
@-moz-keyframes move-twink-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 5000px;
  }
}
@-ms-keyframes move-twink-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 5000px;
  }
}
/* bg ball animations */
.bg--ball1 {
  width: clamp(450px, 45vw, 800px);
  height: clamp(210px, 31vh, 570px);
  position: absolute;
  z-index: 4;
  top: clamp(-100px, -5vh, 0px);
  background: url('../assets/img/green-ball.png') no-repeat bottom;
  background-size: cover;
  opacity: 0.8;  
  -moz-animation: ball1 25s ease-in-out infinite;
  -ms-animation: ball1 25s ease-in-out infinite;
  -o-animation: ball1 25s ease-in-out infinite;
  -webkit-animation: ball1 25s ease-in-out infinite;
  animation: ball1 25s ease-in-out infinite;

  @keyframes ball1 {
    0% {
      left: -20%;
      transform: scale(1);
    }
    50% {
      left: -10%;
      transform: scale(0.8);
    }
    100% {
      left: -20%;
      transform: scale(1);
    }
  }
    @media screen and (min-width: $deskS) and (max-aspect-ratio: 1/1) {
    width: clamp(200px, 60%, 800px);
    height: 260px;
    top: -5%;

    @keyframes ball1 {
      0% {
        left: -20%;
        // transform: scale(1);
      }
      50% {
        left: -15%;
        // transform: scale(0.7);
      }
      100% {
        left: -20%;
        // transform: scale(1);
      }
    }
  }
  @media screen and (max-width: $deskS) {
    width: clamp(200px, 90%, 600px);
      height: 260px;


    @keyframes ball1 {
      0% {
        left: 5%;
        // transform: scale(1);
      }
      50% {
        left: 15%;
        // transform: scale(0.7);
      }
      100% {
        left: 5%;
        // transform: scale(1);
      }
    }
  }

  @media screen and (max-width: $tabletM) {
      width: clamp(460px, 90%, 600px);
      height:clamp(100px, 50%, 200px);
      top: clamp(-350px,-5%, 0px);

    @keyframes ball1 {
      0% {
        left: 10%;
        // transform: scale(1);
      }
      50% {
        left: 20%;
        // transform: scale(0.8);
      }
      100% {
        left: 10%;
        // transform: scale(1);
      }
    }
  }
  @media screen and (max-width: $tabletS) {
      // width: clamp(460px, 90%, 600px);
      // height:clamp(100px, 50%, 200px);
      // top: clamp(-350px,-5vh, 0px);

    @keyframes ball1 {
      0% {
        left: 47%;
        transform: translateX(-50%) scale(1);
        opacity: 0.8;
      }
      50% {
        left: 53%;
        transform: translateX(-50%) scale(0.7);
        opacity: 0.6;
      }
      100% {
        left: 47%;
        transform: translateX(-50%) scale(1);
        opacity: 0.8;
      }
    }
  }
}
.bg--ball2 {
  width: 38vw;
  height: 33vw;
  border-radius:100%;
  background: #16006c;
  filter: blur(30px);
  position: absolute;
  z-index: 3;
  top: -50vh;

  -moz-animation: ball2 20s ease-in-out infinite;
  -ms-animation: ball2 20s ease-in-out infinite;
  -o-animation: ball2 20s ease-in-out infinite;
  -webkit-animation: ball2 20s ease-in-out infinite;
  animation: ball2 20s ease-in-out infinite;
  @keyframes ball2 {
    0% {
      left: 3vw;
      opacity: 0.5;
      transform: scale(0.8);
    }
    50% {
      left: -20vw;
      opacity: 0.8;
      transform: scale(1.3);
    }
    100% {
      left: 3vw;
      opacity: 0.5;
      transform: scale(0.8);
    }
  }

  @media screen and (max-width: $deskS) {
      width:340px;
      height: 140px;
      top: -67px;
    
    @keyframes ball2 {
      0% {
        left: 50%;
        opacity: 0.5;
        transform: scale(0.8);
      }
      50% {
        left: 20%;
        opacity: 0.8;
        transform: scale(1.3);
      }
      100% {
        left: 50%;
        opacity: 0.5;
        transform: scale(0.8);
      }
    }
  }
  @media screen and (max-width: $tabletM) {
      width:340px;
      top: -47px;
    @keyframes ball2 {
      0% {
        left: 40%;
        opacity: 0.5;
        transform: scale(0.8);
      }
      50% {
        left: 0%;
        opacity: 0.8;
        transform: scale(1.3);
      }
      100% {
        left: 40%;
        opacity: 0.5;
        transform: scale(0.8);
      }
    }
  }
}
.bg--ball3 {
  width: 60vw;
  height: 35vw;
  // background: #20dfb3;
  // filter: blur(67px);
  background: url('../assets/img/green-ball.png') no-repeat top;
  background-size: cover;
  position: absolute;
  z-index: 50;
  opacity: 0.5;
  animation: ball3 30s ease-in-out infinite;
  @keyframes ball3 {
    0% {
      bottom: -30%;
      right: -15%;
    }
    33% {
      bottom: 0%;
      right: -25%;
    }
    66% {
      bottom: -30%;
      right: -28%;
    }
    100% {
      bottom: -30%;
      right: -15%;
    }
  }
  @media screen and (max-width: 480px) {
    display: none;
    animation: none;
  }
}
.bg--ball4 {
  width: 5vw;
  height: 5vw;
  border-radius: 100%;
  // background: #20dfb3;
  // filter: blur(38px);
  
  position: absolute;
  z-index: 3;
  bottom: 35%;
  left: 7%;
  animation: ball4 40s ease-in-out infinite;
  @keyframes ball4 {
    0% {
      bottom: 35%;
      left: 7%;
      opacity: 0.5;
    }
    33% {
      bottom: 32%;
      left: 15%;
      opacity: 0.4;
    }
    66% {
      bottom: 30%;
      left: 18%;
      opacity: 0.3;
    }
    100% {
      bottom: 35%;
      left: 7%;
      opacity: 0.5;
    }
  }
}
.bg--ball5 {
  width: 180vw;
  height: 50vw;
  // border-radius: 100%;
  // background: linear-gradient(180deg, #1b7d94 0%, #20dfb3 100%) #1b7d94;
  // filter: blur(98px);
  background: url('../assets/img/green-ball.png') no-repeat top;
  background-size: cover;
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: -2;
  opacity: 0.6;

  @media screen and (max-width: $mobileG) {
    width: 180vw;
    height: 50vw;
    // filter: blur(60px);
    bottom: 0%;
    z-index: 0;
  }
}

@keyframes hero-scroll-text {
  0% {
    transform: translateY(95%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes btns {
  0% {
    filter: blur(100px);
  }
  95% {
    filter: blur(100px);
  }
  100% {
    filter: blur(0px);
  }
}
@keyframes astronaut-message {
  0% {
    transform: scale(0);
    filter: blur(40px);
  }
  100% {
    transform: scale(1);
    filter: blur(0px);
  }
}

.fade-in {
  transform: translateY(30%); /*Entender como estado previo*/
  transition: all 2s ease-out;
  opacity: 0;
}
.delayed-fade-in {
  transition-delay: 500ms;
}

.active-fade-in {
  opacity: 4;
  transform: translateY(0);
}

.blur-fade-in {
  opacity: 0;
  transition: all 500ms ease-out;
  filter: blur(10px);
  filter: hue-rotate("180deg");
}
.active-blur-fade-in {
  opacity: 1;
  filter: blur(0);
  filter: hue-rotate("0deg");
}

@media screen and (max-width: $tabletM) {
  @keyframes img-fade-in {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@keyframes words-cloud__animation {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(359deg);
  }
}
@keyframes word__animation {
  0% {
    transform: rotateZ(359deg);
  }
  100% {
    transform: rotateZ(0);
  }
}

@keyframes words-opacity {
  0% {
    opacity: 0.2;
  }
  33% {
    opacity: 1;
  }
  66% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
.cloud-animation {
  position: absolute;
  animation: words-cloud__animation 35s linear infinite,
    words-opacity 10s linear infinite;
  z-index: 1;
  justify-content: center;
  opacity: 0.2;
  transition: 300ms ease-in;
}
.cloud-animation--design {
  top: 6.5%;
  left: -2.5%;
  width: 35%;
  height: 90%;
  @media screen and (max-width: $deskS) {
    width: 37%;
  }
  @media screen and (max-width: $tabletM) {
    width: 97%;
    height: 45%;
    top: auto;
    bottom: -6%;
    left: 6%;
  }
  @media screen and (max-width: $mobileG) {
    width: 100%;
    height: 38%;
    bottom: -2%;
    left: 6%;
}

}
.cloud-animation--dev {
  top: -6%;
  right: -5%;
  width: 43%;
  height: 97%;
  animation-delay: 0s, 4s;
  @media screen and (max-width: $tabletM) {
    top: -6%;
    right: -9%;
    width: 96%;
    height: 45%;
    animation-delay: 0s, 4s;
  }
  @media screen and (max-width: $tabletM) {
    top: -4%;
  }
}
.cloud-animation--web {
  top: 0%;
  left: 31%;
  width: 40%;
  height: 86%;
  animation-delay: 0s, 8s;
  @media screen and (max-width: $tabletM) {
    top: 33%;
    left: -16%;
    width: 75%;
    height: 35%;
  }
}

.cloud-animation__word {
  font-size: 1.2rem;
  font-weight: 500;
  animation: word__animation 35s linear infinite;
  width: 16.5%;
  align-items: center;
  justify-content: center;
  align-content: center;
  display: flex;
 @media screen and (max-width: $tabletM) {  
  font-size: 1.4rem;
  max-width: 20%;
 }
 @media screen and (max-width: $mobileG) {  
  font-size: 1.2rem;
  // max-width: 50px;
 }
}

.cloud-animation__word--alter {
  font-size: 1.2rem;
  font-weight: bold;
  animation: word__animation 35s linear infinite;
  flex-basis: 30%;
  align-items: center;
  justify-content: center;
  align-content: center;
  display: flex;
  text-align: center;
}
